<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
    class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>
        {{
          Object.entries(this.data).length === 0 ? $t("AddNew") : $t("Update")
        }}
        {{ $t("Item") }}
      </h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>

    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings">
      <vs-card title="icon">
        <div class="vx-row m-4">
          <label class="text-sm opacity-75">{{ $t("StateNameAr") }}</label>
          <vs-input class="w-full" v-model="admin1Name_ar" v-validate="'required'" name="StateNameAr" />
          <span class="text-danger text-sm" v-show="errors.has('admin1Name_ar')">{{ errors.first("admin1Name_ar")
          }}</span>
        </div>
        <div class="vx-row m-4">
          <label class="text-sm opacity-75">{{ $t("StateNameEn") }}</label>
          <vs-input class="w-full" v-model="admin1Name_en" v-validate="'required'" name="StateNameEn" />
          <span class="text-danger text-sm" v-show="errors.has('admin1Name_en')">{{ errors.first("admin1Name_en")
          }}</span>
        </div>
      </vs-card>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData">{{ $t("Submit") }}</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">{{ $t("Cancel") }}</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => { },
    },
  },

  watch: {
    isSidebarActive(val) {
      debugger;
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        let { Id, admin1Name_ar, admin1Name_en } = JSON.parse(
          JSON.stringify(this.data)
        );
        this.Id = Id;
        this.admin1Name_ar = admin1Name_ar;
        this.admin1Name_en = admin1Name_en;
      }
    },
  },

  data() {
    return {
      Id: null,
      admin1Name_ar: "",
      admin1Name_en: "",
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
    };
  },

  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
        }
      },
    },
    isFormValid() {
      return !this.errors.any() && this.Id;
    },
  },

  methods: {
    reloadComponent() {
      this.$refs.state.$forceUpdate();
    },
    initValues() {
      if (this.data.Id) return;
      this.Id = null;
      this.admin1Name_ar = "";
      this.admin1Name_en = "";
      this.CountryCode = "YE";
    },
    RefreshState() {
      this.reloadComponent();
      this.$router.push({ name: 'State' }).catch(() => { });
      window.location.reload();
    },

    // submitData() {
    //   debugger;
    //   this.$validator.validateAll().then((result) => {
    //     if (result) {
    //       const obj = {
    //         Id: this.Id,
    //         admin1Name_ar: this.admin1Name_ar,
    //         admin1Name_en: this.admin1Name_en,
    //         admin0Pcode: this.CountryCode,
    //       };

    //       if (this.Id !== null && this.Id >= 0) {
    //         this.$store
    //           .dispatch("CountryList/UpdateState", obj)
    //           .then(() => {
    //             window.location.reload();
    //             this.$router.go();
    //             this.$store
    //               .dispatch("CountryList/GetStateList")
    //               .then(() => {
    //                 this.$router.push({ name: 'State' }).catch(() => { });
    //                 location.reload();
    //               });
    //             window.showSuccess();
    //           })
    //           .catch((err) => {
    //             console.error(err);
    //           });
    //       } else {
    //         debugger;
    //         delete obj.Id;
    //         obj.popularity = 0;
    //         this.$store
    //           .dispatch("CountryList/addState", obj)
    //           .then(() => {
    //             debugger;
    //             this.$router.push({ name: 'State' }).catch(() => { });
    //              location.reload();
    //             this.$store
    //               .dispatch("CountryList/GetStateList")
    //               .then(() => {
    //                 this.$vs.loading.close();
    //                 window.location.reload(); // reload the page after the state list has been updated
    //               });
    //             window.showSuccess();
    //           })
    //           .catch((err) => {
    //             console.error(err);
    //           });
    //       }
    //       this.$emit("closeSidebar");

    //       this.initValues();
    //     }
    //   });
    // },

    submitData() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const obj = {
            Id: this.Id,
            admin1Name_ar: this.admin1Name_ar,
            admin1Name_en: this.admin1Name_en,
            admin0Pcode: this.CountryCode,
          };

          if (this.Id !== null && this.Id >= 0) {
            this.$store
              .dispatch("CountryList/UpdateState", obj)
              .then(() => {
                debugger;
                var Model = {};
                Model.CountryCode = "YE";
                this.$store.dispatch("CountryList/GetStateList")
                  .then(res => {
                    debugger;
                    this.StateList = res.data;
                  });
                window.showSuccess();
              })
              .catch((err) => {
                console.error(err);
              });
          } else {
            delete obj.Id;
            obj.popularity = 0;
            this.$store
              .dispatch("CountryList/addState", obj)
              .then(() => {
                debugger;
                var Model = {};
                Model.CountryCode = "YE";
                this.RefreshState();
                this.$store.dispatch("CountryList/GetStateList")
                  .then(res => {
                    debugger;
                    this.StateList = res.data;
                  });
                window.showSuccess();
              })
              .catch((err) => {
                console.error(err);
              });
          }

          this.$emit("closeSidebar");
          this.initValues();
          this.RefreshState();
        }
      });
    },
  },

  components: {
    VuePerfectScrollbar,
  },
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
</style>
